<template>
  <div class="copyright-page">
    <div class="content">
      <h1 class="header">免责声明</h1>
      <p>在使用本网站引擎（以下简称本网站）前，请您务必仔细阅读并透彻理解本声明。您可以自愿选择是否使用本网站，但如果您使用本网站，您的使用行为将被视为对本声明全部内容的认可。</p>
      <ol>
        <li>鉴于本网站以非人工检索方式、自动生成到第三方网站的链接，以便用户能够找到和使用第三方网站上各种文档、图片及其他所需内容。本网站自身不存储、控制编辑或修改被链接的第三方网站上的信息内容或其表现形式。对其概不负责，亦不承担任何法律责任。如有认为本网站侵犯您的权益，请及时联系我们删改相关搜索结果。</li>
        <li>任何通过使用本网站而搜索链接到的第三方网站资源均系他人制作或提供，您可能从该第三方网站上获得资源及享用服务，本网站对其合法性概不负责，亦不承担任何法律责任。</li>
        <li>本网站搜索结果根据您键入的关键字自动搜索获得并生成，不代表本网站赞成被搜索链接到的第三方网站上的内容或立场。</li>
        <li>您应该对使用本网站的结果自行承担风险。本网站不做任何形式的保证：不保证搜索结果满足您的要求，不保证搜索服务不中断，不保证搜索结果的安全性、正确性、及时性、合法性，本网站对此不承担任何法律责任。</li>
        <li>本网站旨在方便用户查找学习资料，坚决打击利用网盘及其搜索服务侵犯他人个人隐私和盗版等违法行为，本站对一些非法恶意的搜索进行了屏蔽，屏蔽的词库正在不断的完善中，如您发现有不良非法搜索结果。</li>
        <li>
          如你搜索到链接存在违规侵权等内容，请立即向百度网盘、新浪微盘官方网站进行举报。
          <ul>
            <li>百度网盘违规举报：http://tousu.baidu.com/pan/add</li>
            <li>百度网盘侵权举报：http://copyright.baidu.com/index.php/index/complaint</li>
            <li>微博 @微盘官方微博 违规举报：https://weibo.com/wepan 举报邮箱：vdisk@sina.cn</li>
            <li>微盘的使用建议：http://vdisk.weibo.com/feedback</li>
            <li>提交举报信息，由本站向官方提交举报信息，并删除屏蔽相关信息。</li>
          </ul>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  components: {
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_var.scss';
.copyright-page {
  min-height: 100%;
  background-color: #f8f8f8;
}
.content {
  margin: 20px auto;
  max-width: 900px;
  padding: 20px 40px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-size: 15px;
  line-height: 1.6;
  color: #333;
  text-align: center;
  word-break: break-all;
  .copyright-image {
    width: 60px;
    margin-bottom: 10px;
  }
  .header {
    margin-bottom: 10px;
  }
  p {
    text-align: justify;
    margin-bottom: 10px;
  }
  ol {
    text-align: justify;
    padding-left: 20px;
    li {
      margin-bottom: 10px;
    }
    ul {
      padding-top: 10px;
      padding-left: 30px;
    }
  }
}
@media (max-width: 700px) {
  .content {
    padding: 20px;
  }
}
</style>
